import React, { useRef } from 'react';
import { Divider } from 'retro-react';
import DashboardView from './DashboardView';
import { Chrono } from "react-chrono";
import { SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import { JsonRpcProvider } from '@ethersproject/providers';

const provider = new JsonRpcProvider('https://mainnet.infura.io/v3/2a2ebe5b45cd4c8b9756f1b9decf2bf5');

function Home() {
  const targetRef = useRef(null);
  const jsonRpcUrlMap = {
    1: ['https://eth-mainnet.g.alchemy.com/v2/gtNS7oTx9GlJmgdDA7e7JAY6ewZZAqey'],
    9: ['https://base-mainnet.g.alchemy.com/v2/gtNS7oTx9GlJmgdDA7e7JAY6ewZZAqey']
  }
  const MY_TOKEN_LIST = [
    {
    "name": "bitcoin",
    "address": "0xC0BC84e95864BdfDCd1CCFB8A3AA522E79Ca1410",
    "symbol": "btc",
    "decimals": 8,
    "chainId": 1,
    "logoURI": "https://www.dextools.io/resources/tokens/logos/ether/0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410.png?1717451123897"
  }
]

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const items = [{
    title: "Jul 30 2015",
    cardTitle: "The Frontier",
    url: "https://etherscan.io/block/0",
    cardSubtitle:"Vitalik Buterin launches the Ethereum network and the first block is confirmed.",
    media: {
      type: "IMAGE",
      source: {
        url: "vitalik.jpg"
      }
    }
  },
  {
    title: "Nov 03 2015",
    cardTitle: "ERC-20 is Born",
    url: "https://etherscan.io/tx/0xd628780ba231cefe6a4f6c3da3b683b16f6151dc9753afd8773d3c2d74ac10c8",
    cardSubtitle:"Fabian Vogelsteller, Alex Vande de Sande, and the Mist team deployed the first ERC-20 prototype: MistCoin (MC Token).",
    media: {
      type: "IMAGE",
      source: {
        url: "mistteam.jpg"
      }
    }
  },
  {
    title: "Nov 03 2015",
    cardTitle: "Hello, Bitcoin",
    url: "https://etherscan.io/tx/0xe6f5e591d6527f19eef684ac36cb9e32d8941bf0897fd623dfa1ba19b9893635",
    cardSubtitle:"6 hours later, Spiderwars deployed a meme coin with 21 million supply: bitcoin (btc).",
    media: {
      type: "IMAGE",
      source: {
        url: "redditbtc.png"
      }
    }
  },
  {
    title: "Nov 19 2015",
    cardTitle: "ERC-20 Proposal",
    url: "https://eips.ethereum.org/EIPS/eip-20",
    cardSubtitle:"Vitalik Buterin and Fabian published the ERC-20 token standard proposal.",
    media: {
      type: "IMAGE",
      source: {
        url: "ercproposal.jpg"
      }
    }
  },
  {
    title: "Apr 5 2024",
    cardTitle: "Bitcoin Wrapped",
    url: "https://etherscan.io/tx/0x0406b25590ad06898fdf592de0792b5c8a01ce339fdf9fdbeb78f08692d67aa7",
    cardSubtitle:"Spiderwars deployed a wrapper contract to the 2015 contract to enable listing.",
    media: {
      type: "IMAGE",
      source: {
        url: "btcclouds.jpg"
      }
    }
  },
  {
    title: "May 27 2024",
    cardTitle: "Buy Bitcoin",
    url: "https://etherscan.io/tx/0xd628780ba231cefe6a4f6c3da3b683b16f6151dc9753afd8773d3c2d74ac10c8",
    cardSubtitle:"The LP was set and trading commenced for $btc, one of the oldest tokens in Ethereum history!",
    media: {
      type: "IMAGE",
      source: {
        url: "btcbenjamin.png"
      }
    }
  },
  {
    title: "Jun 21 2024",
    cardTitle: "Bitcoin on Base",
    url: "https://etherscan.io/tx/0xd628780ba231cefe6a4f6c3da3b683b16f6151dc9753afd8773d3c2d74ac10c8",
    cardSubtitle:"Bitcoin was bridged to Coinbase's Base L2 blockchain and soon after trading began!",
    media: {
      type: "IMAGE",
      source: {
        url: "btcbase.jpg"
      }
    }
  }
  ];
  return (
    <div className="Home">
      <div className='Landing'>
      {/* <div className="Hero-Full">
        <img src="btc_banner.jpg" alt="Hero" />
      </div> */}
      <div className="Hero">
        <img src="btc_meme_nb.png" alt="Hero" />
      </div>
      <div className="Home-Header">
        <div className='Home-Details'>
        <h1>bitcoin</h1>
        <h4>on <span style={{color: "#FFEB5C"}}>Ethereum </span>and<span style={{color: "#2165FF"}}> Base</span></h4>
        <p style={{fontStyle:"italic"}}>A meme coin almost as old as Ethereum itself.</p>
        <p>Deployed November 2015, months after Ethereum and hours after the ERC20 standard, bitcoin is an invaluable Ethereum relic.</p>
        <div className="buy-buttons">
        <button onClick={handleScroll} className="buy-button">BUY NOW</button>
        </div>
        </div>
          <div class="container">
        <img src="btc_dance.gif" alt="Moving GIF" class="moving-gif" />
    </div>
      </div>
      </div>
      <Divider color="grayscale" orientation="horizontal" />
      <div className='news'>
      <img src='twittermeme.png' alt='tweet'/>
      </div>
      
      <Divider color="grayscale" orientation="horizontal" />
      <DashboardView />
      <Divider color="grayscale" orientation="horizontal" />
      <div className="How-to-buy" id="buy-bitcoin"  ref={targetRef}>
        <h2>Buy Bitcoin</h2>
        <div className="buy-buttons">
        <a href="https://app.uniswap.org/explore/tokens/ethereum/0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410"
            target="_blank" rel="noopener noreferrer"
            className="buy-button">
            Ethereum (Uniswap)
          </a>
          <a href="https://app.uniswap.org/explore/tokens/base/0xceA0Be764485f3E22Aa843dFfF3D189F5af6a361"
            target="_blank" rel="noopener noreferrer"
            className="buy-button">
            Base (Uniswap)
          </a>
          {/* <div className="Uniswap">
            <SwapWidget 
            defaultInputAmount={1}
            jsonRpcUrl={provider}
            provider={provider}
            defaultOutputTokenAddress={"0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410"}
            tokenList={MY_TOKEN_LIST}

            />
          </div> */}
        </div>
      </div>
      <Divider color="grayscale" orientation="horizontal" />
      <div className="Token-Info">
        <h2>Info</h2>
        <p><span className="header">Token:</span> <span className="value">bitcoin</span></p>
        <p><span className="header">Ticker:</span> <span className="value">$btc</span></p>
        <p><span className="header">ETH Contract Address:</span> <span className="value">0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410</span></p>
        <p><span className="header">Base Contract Address:</span> <span className="value">0xceA0Be764485f3E22Aa843dFfF3D189F5af6a361</span></p>
        <p><span className="header">Chain:</span> <span className="value">Ethereum & Base</span></p>
        <p><span className="header">Supply:</span> <span className="value">21,000,000</span></p>
        <p><span className="header">LP:</span> <span className="value">Locked and Chunky</span></p>
      </div>
      <Divider color="grayscale" orientation="horizontal" />
      <div className='news'>
      <img src='redditmeme.png' alt='reddit'/>
      </div>
      <Divider color="grayscale" orientation="horizontal" />
      <div className="Timeline">
        <h2>Timeline</h2>
        <div className='Timeline-Content'>
        <Chrono items={items} 
          disableToolbar
          mode="VERTICAL_ALTERNATING"
          disableInteraction
          cardHeight="100"
          theme={{
            primary: '#FFA500',
            secondary: 'blue',
            cardTitleColor: "#FFA500",
            cardBgColor: 'rgba(200, 216, 230, 0.2)',
            titleColor: 'white',
            titleColorActive: 'white',
            cardSubtitleColor: "white"
          }}
          fontSizes={{
            cardSubtitle: '0.55rem',
            cardText: '0.55rem',
            cardTitle: '0.6rem',
            title: '0.8rem',
          }}
          enableBreakPoint 
          verticalBreakPoint={1000}
          />
          </div>
        {/* <ul>
          <li>
            <div className="Date">Jul 30, 2015</div>
            <span className="Event">
              The Frontier: Vitalik Buterin launches the Ethereum network and the first block is confirmed.
              <a href="https://etherscan.io/block/0" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
          <li>
            <div className="Date">Nov 03, 2015</div>
            <span className="Event">
              Fabian Vogelsteller deployed the first ERC-20 prototype: MistCoin (MC Token).
              <a href="https://etherscan.io/tx/0xd628780ba231cefe6a4f6c3da3b683b16f6151dc9753afd8773d3c2d74ac10c8" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
          <li>
            <div className="Date">Nov 03, 2015</div>
            <span className="Event">
              6 hours later, Spiderwars deployed a meme coin with 21 million supply: <RainbowText text="bitcoin (btc)" />.
              <a href="https://etherscan.io/tx/0xe6f5e591d6527f19eef684ac36cb9e32d8941bf0897fd623dfa1ba19b9893635" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
          <li>
            <div className="Date">Nov 19, 2015</div>
            <span className="Event">
              Vitalik Buterin and Fabian published the ERC-20 token standard proposal.
              <a href="https://eips.ethereum.org/EIPS/eip-20" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
          <li>
            <div className="Date">April 5, 2024</div>
            <span className="Event">
              Spiderwars deployed a wrapper contract to the 2015 contract to enable listing.
              <a href="https://etherscan.io/tx/0x0406b25590ad06898fdf592de0792b5c8a01ce339fdf9fdbeb78f08692d67aa7" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
          <li>
            <div className="Date">May 27, 2024</div>
            <span className="Event">
              The LP was set and trading commenced for $btc, one of the oldest tokens in Ethereum history!
              <a href="https://app.uniswap.org/explore/tokens/ethereum/0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
          <li>
            <div className="Date">June 21, 2024</div>
            <span className="Event">
              Bitcoin was bridged to Coinbase's Base L2 blockchain and soon after trading began!
              <a href="https://basescan.org/address/0xcea0be764485f3e22aa843dfff3d189f5af6a361" target="_blank" rel="noopener noreferrer">Source</a>
            </span>
          </li>
        </ul> */}
      </div>
      <Divider color="grayscale" orientation="horizontal" />
      <div className="Listings">
        <h2>Listings</h2>
        <div className="listing-images">
          <a href="https://dexscreener.com/ethereum/0x7c2caefe7ef6f5edf53cdcea8d60ea615f102e0c" target="_blank" rel="noopener noreferrer">
            <img src="dexscreener_l.png" alt="Dexscreener" />
          </a>
          <a href="https://coinmarketcap.com/dexscan/ethereum/0x7c2caefe7ef6f5edf53cdcea8d60ea615f102e0c/" target="_blank" rel="noopener noreferrer">
            <img src="coinmarketcap.png" alt="CoinMarketCap" />
          </a>
          <a href="https://www.coingecko.com/en/coins/bitcoin-2015-wrapper-meme" target="_blank" rel="noopener noreferrer">
            <img src="CoinGecko_logo.png" alt="CoinGecko" />
          </a>
          <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x7c2caefe7ef6f5edf53cdcea8d60ea615f102e0c" target="_blank" rel="noopener noreferrer">
            <img src="dextools_l.png" alt="Dextools" />
          </a>
          {/* <a href="https://www.dextools.io/app/en/base/pair-explorer/0xe02db40ad7c8d7fae2bf7d4db51fc7127c579505?t=1719950542830" target="_blank" rel="noopener noreferrer">
            <img src="dextools_l.png" alt="Dextools-Base" />
          </a> */}
          <a href="https://web3.bitget.com/en/swap/eth/0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410" target="_blank" rel="noopener noreferrer">
            <img src="bitgetw.png" alt="Bitget" />
          </a>
          {/* Add more listing images as needed */}
        </div>
      </div>
      <Divider color="grayscale" orientation="horizontal" />
      <div className="Listings">
        <h2>Chains</h2>
        <div className="chain-images">
          <a href="https://etherscan.io/address/0xc0bc84e95864bdfdcd1ccfb8a3aa522e79ca1410" target="_blank" rel="noopener noreferrer">
            <img src="ethereum.png" alt="Ethereum" />
          </a>
          <a href="https://basescan.org/address/0xcea0be764485f3e22aa843dfff3d189f5af6a361" target="_blank" rel="noopener noreferrer">
            <img src="base.png" alt="Base" />
          </a>
        </div>
      </div><footer className="Footer">
          <h3>Connect with BTC on ETH</h3>
          <div className="Social-links">
            <div className="social-images">
              <a href="https://twitter.com/btc_on_eth" target="_blank" rel="noopener noreferrer">
                <img src="twitter.png" alt="Twitter" />
              </a>
              <a href="https://discord.gg/bp57JyBHkt" target="_blank" rel="noopener noreferrer">
                <img src="discord.png" alt="Discord" />
              </a>
              <a href="https://t.me/btc_on_eth" target="_blank" rel="noopener noreferrer">
                <img src="telegram.png" alt="Telegram" />
              </a>
              <a href="mailto: contact@btconeth.com" target="_blank" rel="noopener noreferrer">
                <img src="email.png" alt="Email" />
              </a>
              {/* Add more social media images as needed */}
            </div>
          </div>
        </footer>
    </div>
  );
}

export default Home;
